import './index.css';

export const Footer = () => {
  return (
      <footer className="App-footer">
        <a href="/terms" className="footer-link">Terms & Conditions</a>
        <a href="/privacy" className="footer-link">Privacy Statement</a>
        <a href="/faq" className="footer-link">FAQ</a>
        <a href="/contact" className="footer-link">Contact Us</a>
        <a href="mailto:support@ploutospay.ca" className="footer-link">support@ploutospay.ca</a>
      </footer>
  );
}

