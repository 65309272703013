import React from 'react';
import './index.css';

const ContactPage = () => {
  return (
    <div className="contact-us-container">
      <header className="contact-header">
        <h1>Contact Us</h1>
        <p>We're here to help! Reach out to us with any questions or concerns.</p>
      </header>

      <div className="contact-content">
        <div className="contact-form">
          <h2>Send Us a Message</h2>
          <form>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input type="text" id="name" placeholder="Your Name" required />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input type="email" id="email" placeholder="Your Email" required />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea id="message" rows="5" placeholder="Your Message" required></textarea>
            </div>
            <button type="submit" className="submit-button">Send</button>
          </form>
        </div>

        <div className="contact-info">
          <h2>Get in Touch</h2>
          <p><strong>Email:</strong> support@ploutospay.ca</p>
          {/* <p><strong>Phone:</strong> +1 (800) 123-4567</p> */}
          {/* <p><strong>Address:</strong> 123 Financial Street, Toronto, ON, Canada</p> */}
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
