
import bankModal from './images/mockup.png';
import PageTitle from './page-title'
import './App.css';

function App() {
  return (
    <div className="App">
      <PageTitle title="Ploutos Pay - Home"/>
      {/* <header className="App-header"> */}
        {/* <h1 className="title">Ploutos Pay</h1> */}
      {/* </header> */}
      
      <img src={bankModal} className="App-logo" alt="App Mockup" />
      
      <div>
        {/* <p className="coming-soon">Complete Control of Your Finances</p> */}
        <p className="coming-soon">Coming Soon</p>
      </div>
      
      {/* Footer */}

    </div>
  );
}

export default App;
