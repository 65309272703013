import React, { useState } from 'react';
import './Faq.css';
import { Footer } from './footer';
import PageTitle from './page-title';

function FAQ() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: 'What is Ploutos Pay?',
      answer: 'Ploutos Pay is a mobile application that lets you track spending, see purchase history and much more.',
    },
    {
      question: 'Is Ploutos Pay secure?',
      answer: 'Yes! We use state-of-the-art encryption and security protocols to protect your personal and financial information.',
    },
    {
        question: 'Do you sell my data?',
        answer: 'No, Ploutos Pay will never sell your data.',
      },
    {
      question: 'How do I sign up?',
      answer: 'Simply download our app and follow the on-screen instructions to create an account.',
    },
    {
      question: 'Is Ploutos Pay only available in Canada?',
      answer: 'Yes. Ploutos Pay is currently only available to Canadian residents.',
    },
  ];

  return (
    <>
    <div className="FAQ">
    <PageTitle title="Ploutos Pay - FAQ"/>
      <header className="FAQ-header">
        <h1>Frequently Asked Questions</h1>
        <p>Find answers to common questions about Ploutos Pay below.</p>
      </header>
      <div className="FAQ-content">
        {faqData.map((faq, index) => (
          <div
            key={index}
            className={`FAQ-item ${activeIndex === index ? 'active' : ''}`}
            onClick={() => toggleFAQ(index)}
          >
            <div className="FAQ-question">{faq.question}</div>
            {activeIndex === index && <div className="FAQ-answer">{faq.answer}</div>}
          </div>
        ))}
      </div>

    </div>

      </>
  );
}

export default FAQ;
