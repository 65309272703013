import React, { useState } from 'react';
import './index.css';
// import logo from './logo.svg'; // Replace with your logo if needed

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="Header">
      {/* Logo */}
      <div className="Header-logo">
        {/* <img src={logo} alt="Ploutos Pay Logo" className="Header-logo-image" /> */}
        <a href="/" className="Header-title">Ploutos Pay</a>
      </div>

      {/* Navigation Links */}
      <nav className={`Header-nav ${menuOpen ? 'open' : ''}`}>
        {/* <a href="/" className="Header-link">Home</a> */}
        {/* <a href="/faq" className="Header-link">FAQ</a> */}
        {/* <a href="/about" className="Header-link">About</a> */}
        {/* <a href="/contact" className="Header-link">Contact</a> */}
      </nav>

      {/* Hamburger Menu for Mobile */}
      <button className="Header-menu-button" onClick={toggleMenu}>
        ☰
      </button>
    </header>
  );
}

export default Header;
